<template>
  <div class="my-publish-container">
    <Title  name='我的竞标'></Title>
    <div class="tabel-content">
      <el-table :header-cell-style="{'background':'#f9faff','font-weight': 700,'color':'#666','padding':'12 5px','text-align':'center'}"
                :data="tableData"
                :cell-style="{'text-align':'center'}"
                style="width: 100%">
        <el-table-column prop="releasetime"
                         label="发布时间"
                         width="180">
        </el-table-column>
        <el-table-column prop="bidName"
                         label="标的名称"
                         width="180">
        </el-table-column>
        <el-table-column prop="bidPrice"
                         width="120"
                         label="标的价格(元)">
        </el-table-column>
          <el-table-column
          prop="apprvStatus"
          label="审核状态"
      >
          <template slot-scope="scope">
            <div   :class="(scope.row.apprvStatus=='F')?'failText':'successText'">
      {{scope.row.apprvStatus | getApprvStatus}}
            </div>
          </template>
        </el-table-column>
        
            <el-table-column prop="publishUserName"
                         label="发布人"
                         width="160">
        </el-table-column>
           <el-table-column prop="apprvTime"
                         label="审核时间"
                         width="160">
        </el-table-column>
   
        <el-table-column prop="startTime"
                         label="标的开始时间"
                         width="160">
        </el-table-column>
        <el-table-column prop="endTime"
                         label="标的结束时间"
                         width="160">
        </el-table-column>
        <el-table-column prop="deliveryDate"
                         label="交货时间"
                         width="160">
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          align="center">
          <template slot-scope="scope">
           
            <el-button v-if="  scope.row.apprvStatus === 'P'  && new Date(scope.row.startTime) > new Date() && biddingExamine" size="small" type="text" @click="onWithdraw(scope.row.id)">撤回</el-button>
            <el-button v-if="(scope.row.apprvStatus === 'R'  || scope.row.apprvStatus === 'F'|| scope.row.apprvStatus === 'N') && clientBidPush"  size="small" type="text" @click="toEdit(scope.row.id,scope.row)">修改</el-button>
            <el-button v-if="  scope.row.apprvStatus === 'A' && biddingExamine  " size="small" type="text" @click="toExamine(scope.row.id,scope.row)">审核</el-button>
             <div>
            <el-button v-if="scope.row.apprvStatus === 'P'  || scope.row.apprvStatus === 'A'|| scope.row.apprvStatus === 'F' || scope.row.apprvStatus === 'R'" size="small" type="text" @click="toNewDetail(scope.row.id,scope.row)">查看详情</el-button>
            </div>
            <!-- <el-button v-if="scope.row.apprvStatus === 'P' && new Date(scope.row.startTime) < new Date()" size="small" type="text" @click="toDetail(scope.row.id)">查看</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination"
           v-if="pageManage.total">
        <el-pagination background
                       layout="prev, pager, next"
                       :total='pageManage.total'
                       :page-size='pageManage.pageSize'
                       :current-page="pageManage.pageNum"
                       @current-change="changeCurrentPage">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMyCompetitive,
  withdrawCompetitive,
  myAuthority
} from '../../api';
import Title from '../../component/Title'
export default {
  components: {
    Title
  },
  data () {
    return {  
    biddingExamine:false,
    clientBidPush:false,
      productPictureManage: {
        dialogImageUrl: '',
        dialogVisible: false
      },
      filterForm: {
        type: 1,
        fromDate: '',
        toDate: '',
        apprvStatus: ''
      },
      pageManage: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      tableData: []
    }
  },
  created () {
    this.$store.commit('changeMenuType', 'BUY');
    this.getMyCompetitive();

    if (localStorage.getItem('userPermission').indexOf('client:bid:push') !==-1) {
       this.clientBidPush=true
      }
    if (localStorage.getItem('userPermission').indexOf('bidding:examine') !==-1) {
       this.biddingExamine=true
      }

  },
  filters: {
    getApprvStatus (val) {
      switch (val) {
        case 'A':
          return '待审核';
          break;
        case 'P':
          return '审核通过';
          break;
        case 'F':
          return '审核未通过';
          break;
        case 'N':
          return '未提交';
          break;
        case 'R':
          return '已撤回';
          break;
      }
    }
  },
  computed: {
    chehui: function () {
      this.tableData.forEach(item => {
        return new Date(item.startTime) < new Date() ? true : false
      })
    }
  },
  methods: {
    onWithdraw (id) {
      this.$confirm('此操作将撤回竞标信息, 是否继续?', '立即发布', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        withdrawCompetitive({ id }).then(res => {
          if (res.errno === 0) {
            this.$message({
              type: 'success',
              message: '撤回成功！'
            });
            this.getMyCompetitive();
          }
        });
      });
    },
    toEdit (id,row) {
      if (row.approveRemarks =='' || row.approveRemarks==undefined) {
      this.approveRemarksShow=false
      }else{
       this.approveRemarksShow=true
      }
      this.$router.push({
        path: '/User/PublishCompetitive',
        query: {
          id,
          isEdit: true,
        }
      })
    },
    //审核
    toExamine (id,row) {
      console.log(row);
      if (row.approveRemarks =='' || row.approveRemarks==undefined) {
      this.approveRemarksShow=false
      }else{
       this.approveRemarksShow=true
      }
      this.$router.push({
        path: '/User/PublishCompetitive',
        query: {
          id,
          isEdit: true,
          isExamine:true,
          isRemarksShow:this.approveRemarksShow
        }
      })
    },
    //查看详情 
    toNewDetail(id,row){

      if (row.approveRemarks =='' || row.approveRemarks==undefined ||  row.apprvStatus=='A') {
      this.approveRemarksShow=false
      }else{
       this.approveRemarksShow=true
      }
        this.$router.push({
        path: '/User/PublishCompetitive',
        query: {
          id,
          isEdit: true,
          isExamine:true,
          isHistory:true,
          isRemarksShow:this.approveRemarksShow
        }
      })
    },
    toDetail (id) {
      this.$router.push({
        path: '/home/TenderDetails',
        query: {
          id
        }
      })
    },
   
    getMyCompetitive () {
      getMyCompetitive({
        ...this.pageManage,
      }).then(res => {
        this.tableData = res.data.list;
        this.pageManage.total = res.data.total;
      });
    },
    changeCurrentPage (num) {
      this.pageManage.pageNum = num;
      this.getMyCompetitive();
    }
  }
}
</script>

<style lang="less" scoped>
.my-publish-container {
  background: #fff;

  .pagination {
    padding: 20px;
    box-sizing: border-box;
    text-align: right;
  }
}

.filter-content {
  line-height: 40px;
  margin-top: 12px;
  padding: 10px;
}
.tabel-content {
  padding: 0 10px;
}

/deep/.el-form-item__label {
  font-size: 12px;
}

/deep/.el-table td,
.el-table th {
  padding: 6px 0;
}
.successText{
  color: #000;
}
.failText{
  color: #0a83f1;
}
</style>
